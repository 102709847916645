/*eslint-disable */
const $ = window.jQuery = require('jquery');

import AboutUs from '@/view/Public/AboutUs';
import Loging from '@/view/Authentication/login';
import Signup from '@/view/Authentication/signup';
import {bus} from '@/main';
import consultPortalInfo from '@/components/public/consult-portal-info';
import ecgIconInfo from './ecgIconInfo/';
import publicNgoList from '@/components/public/public-ngo-list';
import welcomeAudioPlay from '@/assets/audio/welcome_record_new.mp3';

export default {
  name: 'landing',
  components: {
    'login':Loging,
    'icon-info': ecgIconInfo,
    'signup' : Signup,
    'about-us':AboutUs,
    'public-ngo-list' : publicNgoList,
    consultPortalInfo
  },
  props: [],
  data () {
    return {
      landingMainContent:true,
      loginPopupState:false,
      iconInfoState:false,
      iconInfoId:null,
      signupPopupState:false,
      aboutUsPopupState:false,
      authBtnShow:true,
      firstTimeVisit: sessionStorage.getItem('firstTimeVisit'),
    }
  },
  computed: {

  },
  mounted () {
    if (localStorage.getItem('token') != null && localStorage.getItem('LoginedUserID') != null) {
      this.$router.push('/dashboard/');
    }
    if (this.firstTimeVisit === null) {
      this.playSound(welcomeAudioPlay)
      sessionStorage.setItem('firstTimeVisit', 'visited')
    }
    bus.$emit('showHeaderbtn', false);
    bus.$emit('showHeaderSection', false);
    if(this.$store.getters.isAuthenticated){
      this.authBtnShow = false
    }
    bus.$on('landingMainContentBus', (data) => {
      this.landingMainContent = data
      $('body').addClass('mainHome')
    });
    bus.$on('loginPopupStateBus', (data) => {
      this.loginPopupState = data
    });
    bus.$on('iconInfoStateBus', (data) => {
      this.iconInfoState = data
    });
    bus.$on('signupPopupStateBus', (data) => {
      this.signupPopupState = data
    });
    bus.$on('aboutUsPopupStateBus', (data) => {
      this.aboutUsPopupState = data
    });
  },
  methods: {
    playSound(sound){
      if(sound){
        var audio = new Audio(sound);
        audio.play();
      }
    },
    ecgInfoPopupEnabled(Id){
      this.iconInfoId = Id
      this.landingMainContent = false
      this.iconInfoState = true
    },
    loginBoxPopupEnabled(){
      this.landingMainContent = false
      this.loginPopupState = true
      $('body').removeClass('mainHome')
    },
    signUpBoxPopupEnabled(){
      this.landingMainContent = false
      this.signupPopupState = true
      $('body').removeClass('mainHome')
    },
    aboutUsPopupEnabled(){
      this.landingMainContent = false
      this.aboutUsPopupState = true
      $('body').removeClass('mainHome')
    },
    logOutUser() {
      if ('BroadcastChannel' in self) {
        authChannel.postMessage({
          cmd: 'logout'
        })
      }
    },
    logout(){
      this.$store.dispatch('logout')
        .then(() => {
          this.logOutUser()
          this.authBtnShow = true
        })
    },
  },
  beforeCreate: function () {
    $('body').addClass('mainHome')
  },
  beforeRouteLeave: function (to, from, next) {
    setTimeout(() => {
      $('body').removeClass('mainHome')
    }, 200);
    next();
  },
}